<template>
  <Main>
    <ToolsPanel :breadCrumbFirst="'Фид'" :title="'Квартира'">
      <div class="list__buttons">
        <MainButton @click.native="handleBack()">К списку квартир</MainButton>
      </div>
    </ToolsPanel>
    <ContentContainer>
      <div v-if="flatData" class="feed">
        <div class="feed__title">Flat Data</div>
        <div class="feed__list">
          <div class="feed__list-item"><span>ID:</span> {{ flatData.id }}</div>
        </div>
      </div>
      <div class="video">
        <div class="video__title">Video List</div>
        <div class="video__list">
          <div v-for="video of videoData" :key="video.id" class="video__list-item">
            <div class="video__status">
              {{ video.status }}
            </div>

            <div v-if="video.status == 'failed' && video.error" class="video__errors">
              <div class="video__error">Message: {{ video.error.message }}</div>
              <div class="video__error">Detail: {{ video.error.detail }}</div>
            </div>
          </div>
        </div>
      </div>
    </ContentContainer>
    <!-- <FetchSpinnerModal v-if="loadingProcess" /> -->
  </Main>
</template>

<script>
import ROUTE from "~/constants/routes";
import feedApi from "~/api/feed";

import Main from "~/templates/Main.vue";
import FetchSpinnerModal from "~/components/molecules/FetchSpinnerModal.vue";
import ToolsPanel from "~/components/molecules/ToolsPanel.vue";
import ContentContainer from "~/components/molecules/ContentContainer.vue";
import MainButton from "~/components/atoms/buttons/MainButton.vue";

export default {
  name: "FlatDetail",
  metaInfo: {
    title: "Flat Detail",
  },
  data() {
    return {
      flatData: null,
      videoData: [],
    };
  },
  components: {
    Main,
    // FetchSpinnerModal,
    ToolsPanel,
    ContentContainer,
    MainButton,
  },
  mounted() {
    this.getItem();
    this.getVideo();
  },
  computed: {
    flatID() {
      return this.$route.params.id;
    },
  },
  methods: {
    getItem() {
      const url = `/${this.flatID}`;
      try {
        feedApi.getItemFlat(url).then((res) => {
          const data = res.data;
          this.flatData = data;
        });
      } catch (error) {
        console.log(error);
      }
    },
    getVideo() {
      const url = `/${this.flatID}`;
      try {
        feedApi.getFlatVideo(url).then((res) => {
          const data = res.data;
          this.videoData = data;
        });
      } catch (error) {
        console.log(error);
      }
    },
    handleBack() {
      this.$router.push({
        path: ROUTE.FEED_LIST.replace(":page", "1"),
      });
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/.custom-table__header,
/deep/.custom-table__row {
  grid-template-columns: 0.4fr 2fr 1fr !important;
}

.feed {
  &__list {
    padding-bottom: 20px;
  }

  &__title {
    font-size: 24px;
    padding-bottom: 20px;
    color: grey;
  }
  &__list-item {
    font-size: 18px;

    span {
      font-weight: 700;
      color: grey;
      padding-right: 40px;
    }
  }
}
.video {
  &__title {
    font-size: 24px;
    padding-bottom: 20px;
    color: grey;
  }
  &__list-item {
    margin-bottom: 20px;
    padding: 15px;
    border: 1px solid gray;
    border-radius: 10px;
  }
  &__status {
    font-size: 18px;
    text-transform: capitalize;
    padding-bottom: 20px;
  }
  &__errors {
    color: red;
  }

  &__error {
    padding-bottom: 10px;
  }
}
</style>
